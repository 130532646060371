import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import range from "lodash/range";

const RATING_LABELS = {
  1: "Very Poor",
  2: "Poor",
  3: "Okay",
  4: "Good",
  5: "Very Good",
};

/**
 * Star rating.
 *
 * @author Nathan Ozelim <nathan@startupfoundry.com>
 */
export default class StarRating extends Component {
  constructor(props) {
    super(props);

    this.state = { rate: props.initialRate, hoveringIdx: 0 };
    this.update = this.update.bind(this);
  }

  update(e, idx) {
    e.stopPropagation();

    if (this.props.readonly) {
      return;
    }

    let newRate = idx;

    /* clicking on the current rate, resets the rating */
    if (newRate == this.state.rate) {
      newRate = this.props.min;
    }

    this.setState({ rate: newRate });
    this.props.onChange(newRate);
  }

  render() {
    const { readonly, max } = this.props;
    const { rate, hoveringIdx } = this.state;
    /* fill stars upon hovering if not read-only */
    const fillUntil = readonly ? 0 : hoveringIdx;

    const classes = classnames("star-rating", this.props.extraClasses, {
      "read-only": readonly,
      filled: rate > 0,
    });

    return (
      <div
        className={classes}
        onMouseOut={() => this.setState({ hoveringIdx: 0 })}
      >
        {range(1, max + 1).map((i) => (
          <i
            key={i}
            onMouseEnter={() => this.setState({ hoveringIdx: i })}
            className={
              "fa-star " +
              (i > Math.max(rate, fillUntil) ? "fa-regular" : "fa-solid")
            }
            onClick={(e) => this.update(e, i)}
            title={RATING_LABELS[i]}
          />
        ))}
      </div>
    );
  }
}

StarRating.defaultProps = {
  readonly: false,
  initialRate: 1,
  min: 0,
  max: 5,
};

StarRating.propTypes = {
  initialRate: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  extraClasses: PropTypes.string,
};
