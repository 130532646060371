import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router";
import Tooltip from "react-tooltip";
import { isMobile } from "common/utils";
import {
  isProjectTagline,
  canProjectHaveFinalRound,
  getDeadline,
} from "project/utils";
import DeadlineLabel from "project/components/deadline_label";

export default class HeaderDeadline extends Component {
  renderTooltipContent(deadline) {
    const { project, user, canClose } = this.props;
    const isOwner = project.client === user.id;
    const deadlineStr = moment(deadline).format("MMMM D, YYYY h:mm a");
    const canHaveFinalRound = canProjectHaveFinalRound(project);

    if (project.status === "closed") {
      return (
        <span>
          {isOwner ? "You" : project.client_username} must pick a winner
          {canHaveFinalRound && " or start a Finalist Round"} by {deadlineStr}.
        </span>
      );
    }

    if (project.status === "finalist_round") {
      return (
        <span>The Finalist Round for this project ends on {deadlineStr}.</span>
      );
    }

    if (project.status === "finalist_closed") {
      return (
        <span>
          {isOwner ? "You" : project.client_username} must pick a winner by{" "}
          {deadlineStr}.
        </span>
      );
    }

    return (
      <span>
        <p>This project is open for submissions until {deadlineStr}.</p>
        {isOwner && (
          <span>
            Already found a{" "}
            {project.entry_type === "image"
              ? "design"
              : isProjectTagline(project)
              ? "tagline"
              : "name"}{" "}
            you love and want to close your project early to declare a winner?{" "}
            {canClose ? (
              "Just click the button below."
            ) : (
              <span>
                Just{" "}
                <Link to="/help/contact/" target="_blank">
                  contact our support team
                </Link>{" "}
                and we'll be happy to help.
              </span>
            )}
          </span>
        )}
      </span>
    );
  }

  render() {
    const { project } = this.props;
    const deadline = getDeadline(project);

    if (!deadline) {
      return null;
    }

    return (
      <span className="project-duration">
        <DeadlineLabel deadline={deadline} />

        <i
          className="fa fa-info-circle"
          data-tip={true}
          data-for="tooltip-deadline"
          data-event={isMobile() ? "click" : undefined}
        />

        <Tooltip
          class="tooltip"
          effect="solid"
          place="left"
          id="tooltip-deadline"
          delayHide={500}
        >
          {this.renderTooltipContent(deadline)}
        </Tooltip>
      </span>
    );
  }
}

HeaderDeadline.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  canClose: PropTypes.bool.isRequired,
};
